import React, { useCallback, useState } from 'react';
import { makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import {
  ButtonPrimary,
  Select,
  INVESTMENT_OBJECTIVE_OPTIONS,
  INVESTMENT_HORIZON_OPTIONS,
  INVESTMENT_EXPERIENCE_OPTIONS,
  INVESTMENT_PRODUCT_OPTIONS,
  INVESTMENT_AFFIRMATION_OPTIONS,
  INVESTMENT_CONCERNS_OPTIONS,
  INVESTMENT_LEVEL_OPTIONS,
  INVESTMENT_SAVINGS_OPTIONS,
  INVESTMENT_PROFESSION_OPTIONS,
  ButtonOutlined,
} from '@components';
import { navigate } from 'gatsby';
import { Route } from '@interfaces';
import { LayoutBudget } from '@components/Structural/Budgets/Layout/Layout';
import { IlustrationDiscover } from '@images';
import { useAuth, useForm } from '@hooks';
import { calculateSuggestedProfile, calculateSuggestedProfile2 } from '@utils';

const Descubrir = () => {
  useAuth({ redirectIfNotLogged: true });

  const classes = useStyles();
  const [form] = useForm();
  const [investmentObjective, setInvestmentObjective] = useState<string>(
    form.investmentObjective ? form.investmentObjective : ''
  );
  const [investmentHorizon, setInvestmentHorizon] = useState<string>(
    form.investmentHorizon ? form.investmentHorizon : ''
  );
  const [product, setProduct] = useState<string>(
    form.product ? form.product : ''
  );
  const [affirmation, setAffirmation] = useState<string>(
    form.affirmation ? form.affirmation : ''
  );
  const [experience, setExperience] = useState<string>(
    form.investmentExperience ? form.investmentExperience : ''
  );
  const [concern, setConcern] = useState<string>(
    form.investmentConcern ? form.investmentConcern : ''
  );
  const [level, setLevel] = useState<string>(form.level ? form.level : '');
  const [savings, setSavings] = useState<string>(
    form.savings ? form.savings : ''
  );
  const [profession, setProfession] = useState<string>(
    form.investmentProfession ? form.investmentProfession : ''
  );
  const [investorProfile, setInvestorProfile] = useState('');

  const handleDiscoverClick = useCallback(() => {
    const result = calculateSuggestedProfile2(
      investmentObjective,
      investmentHorizon,
      product,
      affirmation,
      experience,
      concern,
      level,
      savings,
      profession
    );
    if (result.completed) {
      form.investmentObjective = investmentObjective;
      form.investmentHorizon = investmentHorizon;
      form.product = product;
      form.affirmation = affirmation;
      form.investmentExperience = experience;
      form.investmentConcern = concern;
      form.level = level;
      form.savings = savings;
      form.investmentProfession = profession;
    }
    setInvestorProfile(result.profile);
  }, [
    form,
    setInvestorProfile,
    investmentObjective,
    investmentHorizon,
    product,
    affirmation,
    experience,
    concern,
    level,
    savings,
    profession,
  ]);

  const isMobile = useMediaQuery('(max-width: 1280px)');
  const [isStep, setStep] = useState(-1);
  return (
    <LayoutBudget
      title="Test de Perfil de Inversión"
      subtitle={
        isStep === -1
          ? 'Paso 1: cuéntanos un poco de ti'
          : isStep === 0
          ? 'Paso 2: sólo un par de preguntas más'
          : 'Paso 3: Tu resultado'
      }
      onTurnBackClick={() =>
        isStep === -1
          ? navigate(Route.rokipedia + '/?#tools')
          : isStep === 0
          ? setStep(-1)
          : setStep(0)
      }
    >
      {isStep !== 1 && (
        <div className={classes.title}>
          Así podrás descubrir inversiones que se ajusten a tus objetivos.
        </div>
      )}
      {isStep === -1 && (
        <section className={classes.containerData}>
          <div className="container">
            <Select
              className={classes.input}
              name={'investmentObjective'}
              label={'¿Cuál es el principal objetivo de sus inversiones?'}
              options={INVESTMENT_OBJECTIVE_OPTIONS}
              value={investmentObjective}
              onChange={(e) => setInvestmentObjective(e.target.value as string)}
            />
            <Select
              className={classes.input}
              name={'investmentHorizon'}
              label={
                '¿Cuál de estas alternativas representa mejor su horizonte de inversión?'
              }
              value={investmentHorizon}
              options={INVESTMENT_HORIZON_OPTIONS}
              onChange={(e) => setInvestmentHorizon(e.target.value as string)}
            />
            <Select
              className={classes.input}
              name={'investmentExperience'}
              label={
                '¿Podría indicarnos qué grado de conocimientos financieros posee?'
              }
              value={experience}
              options={INVESTMENT_EXPERIENCE_OPTIONS}
              onChange={(e) => setExperience(e.target.value as string)}
            />
            <Select
              className={classes.input}
              name={'product'}
              label={
                'Indique el producto más sofisticado en el cual ha invertido o invierte:'
              }
              value={product}
              options={INVESTMENT_PRODUCT_OPTIONS}
              onChange={(e) => setProduct(e.target.value as string)}
            />
            <Select
              className={classes.input}
              name={'affirmation'}
              label={
                '¿Con cuál de las siguientes afirmaciones se identifica mejor?'
              }
              value={affirmation}
              options={INVESTMENT_AFFIRMATION_OPTIONS}
              onChange={(e) => setAffirmation(e.target.value as string)}
            />
          </div>
          <div className={classes.buttonContinue}>
            <ButtonPrimary
              text="Continuar"
              disabled={
                !(
                  investmentObjective &&
                  investmentHorizon &&
                  experience &&
                  product &&
                  affirmation
                )
              }
              onClick={() => {
                setStep(0);
              }}
            />
          </div>
        </section>
      )}
      {isStep === 0 && (
        <section className={classes.containerData}>
          <div className="container">
            <Select
              className={`${classes.input} ${classes.specialInput}`}
              name={'investmentConcern'}
              label={
                'Si tiene una inversión con una rentabilidad de 30% y producto de variaciones en el mercado tiene una baja de 20% quedándole un 10% a favor, ¿qué haría?'
              }
              value={concern}
              options={INVESTMENT_CONCERNS_OPTIONS}
              onChange={(e) => setConcern(e.target.value as string)}
            />
            <Select
              className={classes.input}
              name={'level'}
              label={
                '¿Cuál es su nivel de renta líquida mensual considerando todas sus fuentes de ingreso?'
              }
              value={level}
              options={INVESTMENT_LEVEL_OPTIONS}
              onChange={(e) => setLevel(e.target.value as string)}
            />
            <Select
              className={classes.input}
              name={'savings'}
              label={'¿Cuánto de sus ahorros está dispuesto a invertir?'}
              value={savings}
              options={INVESTMENT_SAVINGS_OPTIONS}
              onChange={(e) => setSavings(e.target.value as string)}
            />
            <Select
              className={classes.input}
              name={'investmentConcern'}
              label={'Tu profesión, respecto a los temas financieros:'}
              value={profession}
              options={INVESTMENT_PROFESSION_OPTIONS}
              onChange={(e) => setProfession(e.target.value as string)}
            />
          </div>
          <div className={classes.buttonContinue}>
            <ButtonPrimary
              text="Continuar"
              disabled={!(concern && level && savings && profession)}
              onClick={() => {
                handleDiscoverClick();
                setStep(1);
              }}
            />
          </div>
        </section>
      )}
      {isStep === 1 && (
        <section className={classes.finish}>
          {!isMobile && (
            <img src={IlustrationDiscover} className={classes.ilustration} />
          )}
          <div className="right">
            <div className={'card'}>
              {isMobile && (
                <img
                  src={IlustrationDiscover}
                  className={classes.ilustration}
                />
              )}
              <div className="title">Tu perfil recomendado</div>
              <div className="profile">{investorProfile}</div>
              <div className="text">{description[investorProfile]}</div>
            </div>
            <div className="buttons">
              <ButtonOutlined
                text="Ir atras y editar"
                onClick={() => {
                  setStep(-1);
                }}
              />
              <ButtonPrimary
                text="Guardar y volver a Mis finanzas"
                onClick={() => {
                  navigate(Route.finance);
                }}
              />
            </div>
          </div>
        </section>
      )}
    </LayoutBudget>
  );
};
export default Descubrir;

const useStyles = makeStyles<Theme>((theme) => ({
  containerData: {
    display: 'grid',
    maxWidth: 1100,
    margin: '0 auto',
    boxShadow: '4px 4px 20px 2px rgba(0, 0, 0, 0.2)',
    background: 'white',
    padding: '20px 15px',
    borderRadius: 30,
    marginBottom: '10rem',
    color: theme.palette.text.primary,
    [theme.breakpoints.down(1280)]: {
      width: 375,
    },
    '& >div': {
      width: '100%',
      [theme.breakpoints.down(1280)]: {
        width: 300,
      },
      margin: '15px 0',
    },
    '& .container': {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '48% 48%',
      gap: '1rem',
      justifyContent: 'space-between',

      [theme.breakpoints.down(1280)]: {
        width: 340,
        gridTemplateColumns: '100%',
        gap: '0rem',
      },
      margin: '15px 0',
    },
  },
  input: {
    width: '100%',
    margin: '10px auto',
    maxWidth: '500px',
    '& >label': {
      fontSize: '0.8rem',
      maxWidth: '93%',
    },
    [theme.breakpoints.down(1000)]: {
      '& >label': {
        fontSize: '0.57rem',
        maxWidth: '90%',
        left: '-0.5rem',
      },
    },
  },
  buttonContinue: {
    width: '500px',
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down(1280)]: {
      justifyContent: 'center',
      width: '100%!important',
    },
  },
  title: {
    maxWidth: 1100,
    margin: '0 auto',
    fontFamily: 'Montserrat',
    paddingLeft: 50,
    marginTop: 50,
    marginBottom: 30,
    color: theme.palette.text.primary,
    [theme.breakpoints.down(1280)]: {
      width: 375,
      paddingLeft: 0,
      textAlign: 'center',
    },
  },
  finish: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '5rem',
    marginTop: '2rem',
    gap: '7rem',
    marginBottom: '2rem',
    fontFamily: 'Montserrat',
    [theme.breakpoints.down(1280)]: {
      marginLeft: 0,
    },
    '& .buttons': {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(1280)]: {
        display: 'grid',
        gap: '2rem',
        justifyContent: 'center',
        margin: 0,
      },
    },
    '& .card': {
      background: 'flex',
      boxShadow: '4px 4px 20px 2px rgba(0, 0, 0, 0.2)',
      borderRadius: 20,
      width: 520,
      height: 400,
      display: 'grid',
      gridTemplateRows: '100px 50px 200px',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      marginBottom: '4rem',
      [theme.breakpoints.down(1280)]: {
        width: 375,
        minHeight: 400,
        height: 'auto',
        gridTemplateRows: '190px 48px 49px 104px',
        '& img': {
          width: 200,
          margin: '0 auto',
        },
      },
      '& .title': {
        fontSize: 20,
        fontWeight: 300,
        textTransform: 'uppercase',
      },
      '& .profile': {
        fontFamily: 'Montserrat',
        fontSize: 40,
        fontWeight: 'bold',
      },
      '& .text': {
        fontWeight: 300,
      },
    },
  },
}));
const description: { [key: string]: string } = {
  Conservador:
    'Eres un inversionista que se caracteriza por ser el menos tolerante al riesgo y valorar la seguridad, aunque esto implique que las ganancias obtenidas sean bajas.',
  'Conservador Moderado':
    'Eres poco tolerante al riesgo, **buscas estabilidad antes que el crecimiento de tu inversión.**',
  Moderado:
    'Eres un inversionista cauteloso con tus decisiones, pero estás dispuesto a correr cierto riesgo para aumentar tus ganancias.',
  'Moderado Agresivo':
    'Eres un inversionista tolerante al riesgo, buscas el crecimiento de tu inversión, pero de igual forma buscas cierta seguridad.',
  Agresivo:
    'Eres un inversionista amante al riesgo! Buscas la mayor rentabilidad posible, a pesar de que eso implique obtener ganancias o pérdidas repentinas.',
};
